import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/__01.LAVORO_wip/ArcheoSIT/sito/index.js";
import * as Container from "../components/container.js";
import * as Section from "../components/splitSections.js";
import { Button } from "../components/button.js";
import Editoria from "../components/editoria.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container.Fluid id="editoria" className="py-5 px-3">
      <Section.FullWidth>
        <h3>{`Editoria`}</h3>
      </Section.FullWidth>
      <Section.FullWidth className="py-4 px-5 mb-4 pt-lg-0 pb-lg-0 pt-lg-0" style={{
        "background": "#A9461D",
        "borderRadius": "8px",
        "boxShadow": "0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04)"
      }}>
        <div className="py-3 py-lg-5" style={{
          color: "#ffffff"
        }}>
          <h4>{`Hai scritto una tesi di laurea o hai un progetto editoriale? Segnalaci il tuo lavoro.`}</h4>
          <h5>{`Lo inseriamo gratuitamente nella nostra sezione editoriale.`}</h5>
          <Button solid large inline targetBlank color={"#2C7784"} hrefJSON={"cta_editoria"} title="Contattaci per inserire il tuo lavoro in questa sezione" mdxType="Button">
Contattaci
          </Button>
        </div>
      </Section.FullWidth>
      <Editoria itemsPerPage={4} mdxType="Editoria" />
    </Container.Fluid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      